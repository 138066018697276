import React, { Component } from 'react';

import { getExperience } from "../util/ExperienceUtils";

const individualChallengeTabs = ['Today', 'Habits', 'Activity', 'Discover']
const individualChallengeTabHomes = { 'Today' : '/', 'Activity' : '/activity', 'Habits' : '/goals', 'Discover' : '/discover' }

const teamChallengeTabs = ['Habits', 'Activity', 'Leaderboard']
const teamChallengeTabsHomes = { 'Habits' : '/team-challenge-home', 'Activity' : '/team-challenge-activity', 'Leaderboard' : '/team-challenge-leaderboard' };

class TabBar extends Component {
    constructor(props) {
      super(props);
    }

    selectTab(tab) {

      if (getExperience() === 'individual-challenge')
        this.props.history.push(individualChallengeTabHomes[tab]);
      else
        this.props.history.push(teamChallengeTabsHomes[tab]);
    } 
    
    renderItems = () => {
      var selectedTab = this.props.tab;

      const tabs = (getExperience() === 'individual-challenge') ? individualChallengeTabs : teamChallengeTabs;

      const items = []
      const tabBarClass = (tabs.length === 4) ? 'tabBarItem4' : 'tabBarItem3';

      for (const [i, tab] of tabs.entries()) {
        var icon = (tab === selectedTab) ? "/icons/" + tab.toLowerCase() + "-icon.png" : "/icons/" + tab.toLowerCase() + "-icon-disabled.png";
        var labelClass = (tab === selectedTab) ? "tabBarLabel" : "tabBarLabelDisabled"


        items.push(         
          <div key={tab} className={tabBarClass} onClick={() => this.selectTab(tab)}>  
            <div className="tabBarIconContainer">
                <img className="tabBarIcon" src={icon}/>
            </div>
            <div className="tabBarLabelContainer">
              <span className={labelClass}>{tab}</span>
            </div>        
          </div>);
      }

      return items;
    }

    render() {
      return (
          <div className="tabBarContainer">
            { this.renderItems() }
          </div>
      );
    }
}

export default TabBar;