import moment from 'moment';
import { func } from 'prop-types';

const calendarDateFormat = 'YYYY-MM-DD';

export function isTeamChallengeInEffectAfterDate(challenge, dateFormatted) {
    return challenge.startDate >= dateFormatted;
}

export function isTeamChallengeInEffectBeforeDate(challenge, dateFormatted) {
    return challenge.endDate < dateFormatted;
}

export function isTeamChallengeInEffectOnDate(challenge, dateFormatted) {
    return challenge.startDate <= dateFormatted && (!challenge.endDate || challenge.endDate >= dateFormatted);
}

export function findNextTeamChallenge(team, dateFormatted) {
    if (!team || !team['team-challenges'])
        return null;
    
    for (var teamChallengeId of Object.keys(team['team-challenges'])) {
        if (isTeamChallengeInEffectAfterDate(team['team-challenges'][teamChallengeId], dateFormatted)) {
            var challenge = team['team-challenges'][teamChallengeId];
            challenge.id = teamChallengeId;
            return challenge
        }
    }

    return null;
}

export function findLastTeamChallenge(team, dateFormatted) {
    if (!team || !team['team-challenges'])
        return null;
    
    for (var teamChallengeId of Object.keys(team['team-challenges'])) {
        if (isTeamChallengeInEffectBeforeDate(team['team-challenges'][teamChallengeId], dateFormatted)) {
            var challenge = team['team-challenges'][teamChallengeId];
            challenge.id = teamChallengeId;
            return challenge
        }
    }

    return null;
}

export function findCurrentTeamChallenge(team, dateFormatted) {
    if (!team || !team['team-challenges'])
        return null;
    
    for (var teamChallengeId of Object.keys(team['team-challenges'])) {
        if (isTeamChallengeInEffectOnDate(team['team-challenges'][teamChallengeId], dateFormatted)) {
            var challenge = team['team-challenges'][teamChallengeId];
            challenge.id = teamChallengeId;
            return challenge
        }
    }

    return null;
}

export function determineChallengeWeekDays(challenge) {
    var date = moment(challenge.startDate, calendarDateFormat);
    var weekDayCount = 0

    while (date.format(calendarDateFormat) <= challenge.endDate) {
        if (date.day() != 0 && date.day() != 6)
            weekDayCount += 1;

        date.add(1, 'days');
    }
    return weekDayCount
}

export function determineDayOfChallenge(challenge, dateFormatted) {
    var date = moment(challenge.startDate, calendarDateFormat);
    var dayCount = 0

    while (date.format(calendarDateFormat) <= dateFormatted) {
        if (date.day() != 0 && date.day() != 6)
            dayCount += 1;

        date.add(1, 'days');
    }
    return dayCount
}

export function parseCheckins(checkins) {
    var checkinsMap = {}
    if (checkins) {
        var checkinsArray = checkins.split(',');
        for (var checkin of checkinsArray) {
            var [ habitId, checkinCount ] = checkin.split(':');
            checkinsMap[habitId] = parseInt(checkinCount);
        }
    }
    return checkinsMap
}

export function serialiseCheckins(checkinsMap) {
    var checkins = []
    for (var habitId of Object.keys(checkinsMap)) {
        checkins.push(habitId + ":" + checkinsMap[habitId]);
    }
    return checkins.join(',')
}

export function getCheckinsForUser(challenge, userId) {
    if (!challenge.checkins || !challenge.checkins[userId])
        return {};

    const checkins = parseCheckins(challenge.checkins[userId]);

    return checkins
}


export function getTotalCheckinsForUser(challenge, userId) {
    if (!challenge.checkins || !challenge.checkins[userId])
        return 0;

    const checkins = parseCheckins(challenge.checkins[userId]);

    var total = 0
    for (const habitId of Object.keys(checkins)) {
        total += checkins[habitId];
    }

    return total;
}

export function getCheckinsForUserAndHabit(challenge, userId, habitId) {
    if (!challenge.checkins || !challenge.checkins[userId])
        return 0;

    const checkins = parseCheckins(challenge.checkins[userId]);
    return checkins[habitId] ? checkins[habitId] : 0;
}

export function getTotalPointsForUser(challenge, userId) {
    if (!challenge.checkins || !challenge.checkins[userId])
        return 0;

    const checkins = parseCheckins(challenge.checkins[userId]);

    var total = 0
    for (const habitId of Object.keys(checkins)) {
        total += challenge.habits[habitId].points * checkins[habitId];
    }

    return total;
}

export function rankUsers(challenge) {
    var userPoints = {}
    for (const userId of Object.keys(challenge.checkins)) {
        userPoints[userId] = getTotalPointsForUser(challenge, userId);
    }

    var sortedUsers = Object.keys(userPoints).sort(function(a, b) {
        return userPoints[b] - userPoints[a];
    });

    var userRanks = []
    var rank = 1
    var lastPoints = -1
    for (var userId of sortedUsers) {
        if (userPoints[userId] != lastPoints) {
            rank += 1
            lastPoints = userPoints[userId]
        }
        userRanks.push(userId)
    }

    return userRanks
}

export function getRankForUser(challenge, userId) {
    var userRanks = rankUsers(challenge)
    var rankIndex = userRanks.indexOf(userId)

    return (rankIndex >= 0) ? userRanks.indexOf(userId) + 1 : 0
}


