import React, { Component } from 'react';

import moment from 'moment';

import AppChrome from '../layout/AppChrome';
import Button from '../ui/Button';

import { getUserAndTeam } from '../util/DatabaseUtils';
import { setExperience } from '../util/ExperienceUtils';

import SpinnerPanel from '../ui/SpinnerPanel';

import TeamChallengeHabitPopup from './TeamChallengeHabitPopup';

import { findCurrentTeamChallenge, findLastTeamChallenge, findNextTeamChallenge, determineChallengeWeekDays, determineDayOfChallenge, getTotalCheckinsForUser, getTotalPointsForUser, getCheckinsForUserAndHabit, getRankForUser  } from '../util/TeamChallengeUtils';

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = {
  dataLoaded: false,
  isOffline : false
};

class TeamChallengeHome extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = (force, cb) => {

    const todayFormatted = moment().format(calendarDateFormat);

    if (this.props.firebase.user && (!this.state.dataLoaded || force)) {
      getUserAndTeam(this.props.firebase, (user, team) => {
        this.setState({ 
            dataLoaded: true, 
            user: user, 
            team : team, 
            currentChallenge: findCurrentTeamChallenge(team, todayFormatted),
            nextChallenge: findNextTeamChallenge(team, todayFormatted),
            lastChallenge: findLastTeamChallenge(team, todayFormatted)
         });
         if (cb) cb();
      },
      () => {
        this.setState({ isOffline: true })
      })
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded(false);
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded(false);
  }

  componentWillUnmount() {}

  switchUX = () => {
    setExperience('individual-challenge')

    this.props.history.push('/')
  }

  sortHabits = (challenge) => {
    var habits = []

    for (var habitId of Object.keys(challenge.habits)) {
      var habit = challenge.habits[habitId]
      habit.id = habitId
      habits.push(habit)
    }

    return habits
  }

  displayHabitPopupWithDetails = (habitId, habitName, challengeId) => {
    this.setState({ displayHabitPopup : true, 
      displayHabitId : habitId, 
      displayHabitName : habitName,
      displayChallengeId : challengeId
    })
  }

  closeHabitPopup = (success) => {
    this.checkDataLoaded(true, () => {
      this.setState({ displayHabitPopup : false, displayHabitId : null })
    });
  }

  renderChallenge = (challenge, active) => {
    var items = []

    const dayOfChallenge = determineDayOfChallenge(challenge, moment().format(calendarDateFormat))
    const challengeWeekDays = determineChallengeWeekDays(challenge)

    items.push(<img class="curvesImage" src="/illustration/curves.png"></img>)
    items.push(<div className='dayOfChallengeText'> Day {dayOfChallenge} of {challengeWeekDays} day challenge</div>)

    const percentage = 100 * (dayOfChallenge / challengeWeekDays)
    items.push(<div className='dayOfChallengeProgressBorder'>
      <div className='dayOfChallengeProgressBar' style={{ width: percentage + '%' }}/>
    </div>)

    const checkins = getTotalCheckinsForUser(challenge, this.props.firebase.user.uid); 
    const points = getTotalPointsForUser(challenge, this.props.firebase.user.uid); 
    const rank = getRankForUser(challenge, this.props.firebase.user.uid); 

    items.push(<div className='checksPointsRankBar'>
      <div className='checksPointsRanksCheckinsBox'>
        <div className='checksPointsRankBarStat'>{checkins}</div>
        <div className='checksPointsRankBarStatLabel'>CHECKINS</div>
      </div>

      <div className='checksPointsRanksPointsBox'>
        <div className='checksPointsRankBarStat'>{points}</div>
        <div className='checksPointsRankBarStatLabel'>POINTS</div>
      </div>

      <div className='checksPointsRanksRankBox'>
        <div className='checksPointsRankBarStat'>{rank}</div>
        <div className='checksPointsRankBarStatLabel'>RANK</div>
      </div>
    </div>)

    const sortedHabits = this.sortHabits(challenge)

    for (var habit of sortedHabits) {
        const checkinsForHabit = getCheckinsForUserAndHabit(challenge, this.props.firebase.user.uid, habit.id)  
        const checkinsForHabitLabel = checkinsForHabit === 1 ? 'CHECKIN' : 'CHECKINS'
        items.push(<div onClick={this.displayHabitPopupWithDetails.bind(this, habit.id, habit.name, challenge.id)} className='teamChallengeHabitCard'>
          <div className='teamChallengeHabitCardName'>{habit.name}</div>
          <div className='teamChallengeHabitCardPoints'>{checkinsForHabit} {checkinsForHabitLabel}</div>
          <div className='teamChallengeHabitCardCheckins'>+</div>
        </div>)
    }

    return items;

  }

  renderTeamChallenge = () => {
    var items = [];

    // Figure out which mode we are in and render the appropriate UI

    if (this.state.currentChallenge) {
      items.push(this.renderChallenge(this.state.currentChallenge, true))
     // items.push(this.renderChallenge(this.state.currentChallange, true))
    }
    else if (this.state.lastChallenge && !this.state.nextChallenge) {
      items.push(<p>Last Challenge: {JSON.stringify(this.state.lastChallenge)}</p>)
    }
    else if (this.state.nextChallenge) {
      items.push(<p>Next Challenge: {JSON.stringify(this.state.nextChallenge)}</p>)
    }
    else {
      items.push(<p>No challenges found</p>)
    }

    items.push(<div className='homePageSwitchButton'>
        <br/>
        <br/>
        <Button onClick={this.switchUX} action='Switch UX'/>
      </div>)

    return items;
  }
  
  render = () => {
    var history = this.props.history;

    return (
      <AppChrome history={history} requireLogin={true} user={this.state.user} team={this.state.team} firebase={this.props.firebase} tab="Habits">
        { this.state.isOffline && <p>Offline</p> }
        { !this.state.isOffline && !this.state.dataLoaded && <SpinnerPanel /> }
        { !this.state.isOffline && this.state.dataLoaded && this.renderTeamChallenge() }
        { this.state.displayHabitPopup && <TeamChallengeHabitPopup teamId={this.state.user.team} team={this.state.team} habitId={this.state.displayHabitId} habitName={this.state.displayHabitName} challengeId={this.state.displayChallengeId} firebase={this.props.firebase} onClose={this.closeHabitPopup}/>}
      </AppChrome>
    )
  }
}

export default TeamChallengeHome;