import React, { Component } from 'react';

import AppChrome from '../layout/AppChrome';

import Activity from '../activity/Activity';

import { getUserAndTeam } from '../util/DatabaseUtils';

class TeamChallengeActivity extends Activity {
  constructor(props) {
    super(props);
  }


  render = () => {
    return this.renderActivityList("Team Activity")
  }
}

export default TeamChallengeActivity;